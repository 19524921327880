import { all, call, put, select } from "redux-saga/effects";
import { first, get } from "lodash";
import { push } from "connected-react-router";

import * as decks from "../decks";
import * as gameModule from "../game";
import * as players from "../players";
import * as playerVotes from "../player-votes";
import * as rounds from "../rounds";
import * as shared from "../shared";
import * as login from "../login";
import * as gameTimerActions from "../game-timer/actions";

import { sessionService } from "../../services";
import { transitions } from ".";
import { gameNoteActions } from "../notes/actions";

const loading = {
  *request(action) {
    try {
      const { payload } = action;
      const state = yield select();
      const sessionContextLoaded = state.login.collections.currentOrganization !== null;

      if (!sessionContextLoaded) {
        console.log("sessionContext about to be called 1");
        yield call(
          login.sagas.session.sessionContext,
          login.actions.session.context.request(),
        );
      }

      if (payload.game && payload.game.id) {
        const game = yield call(
          gameModule.sagas.get,
          gameModule.actions.get.request({ id: payload.game.id }),
        );

        if (game.payload.deck && game.payload.deck.id) {
          yield call(
            decks.sagas.deck.get,
            decks.actions.deck.get.request({ id: game.payload.deck.id }),
          );
        }

        yield put(gameNoteActions.get.request({ gameId: payload.game.id }));
        yield put(players.actions.fetch.request({ gameId: payload.game.id }));
        yield put(gameModule.actions.tick.refresh({ gameId: payload.game.id }));
      }

      yield put(transitions.loading.success());
    } catch (error) {
      yield put(transitions.loading.error(error));
      throw error;
    }
  },
};

const resolve = {
  *current() {
    try {
      const oldState = yield select();

      if (oldState.game.id) {
        yield call(gameModule.sagas.get, gameModule.actions.get.request({ id: oldState.game.id }));
      }

      const state = yield select();
      const game = shared.selectors.getByPath({ state, path: "game" });
      const payloads = {
        game: { id: game.id },
      };

      yield put(gameTimerActions.command.stop());

      // State guards
      const guards = {
        setup: () => !game.id,
        waitplayers: () => game.state === "waitplayers",
        preround: () => game.state === "preround",
        round: () => game.state === "rounds",
        pickTopaasia: () => game.state === "pick-topaasia",
        crystallization: () => game.state === "crystallize",
        complete: () => game.state === "complete",
      };

      switch (true) {
        case guards.setup():
          // Transition to game setup state
          yield put(transitions.resolve.setup());
          break;

        case guards.waitplayers():
          // Transition to wait players state
          yield put(transitions.resolve.waitplayers());
          break;

        case guards.preround():
          // Transition to preround state
          yield put(transitions.resolve.preround(payloads.game));
          break;

        case guards.round(): {
          yield put(transitions.resolve.round(payloads.game));
          break;
        }

        case guards.pickTopaasia():
          // Transition to pickTopaasia state
          yield put(transitions.resolve.pickTopaasia(payloads.game));
          break;

        case guards.crystallization(): {
          // Transition to crystallization state
          yield put(transitions.resolve.crystallization(payloads.game));
          break;
        }

        case guards.complete():
        default:
          // Transition to complete state
          yield put(transitions.resolve.complete(payloads.game));
          break;
      }
    } catch (error) {
      yield call(sessionService.remove);
      yield put(transitions.resolve.error(error));
      throw error;
    }
  },
};

const setup = {
  submitting: {
    *request(action) {
      const { payload } = action;

      try {
        yield call(gameModule.sagas.post, gameModule.actions.post.request(payload));

        const state = yield select();
        const { game } = state;

        yield call(sessionService.set, {
          id: game.id,
        });

        yield put(transitions.setup.submitting.success());
        yield put(push(`/game/${game.id}`));
      } catch (error) {
        yield put(transitions.setup.submitting.error(error));
      }
    },
  },
};

const waitplayers = {
  resolve: {
    *request() {
      try {
        const state = yield select();
        const gameId = state.game.id;
        yield put(gameModule.actions.tick.refresh({ gameId }));
        yield put(players.actions.refresh.request({ gameId }));
      } catch (error) {
        yield put(players.actions.get.error(error));
      }
    },
  },
  submitting: {
    *request(action) {
      const gameId = action.payload.id;

      try {
        yield call(
          gameModule.sagas.waitplayers.patch,
          gameModule.actions.waitplayers.patch.request(action.payload),
        );
        yield put(players.actions.refresh.stop());

        // Start the next round immediately without rendering the preround state.
        // Prevents an unnecessary loading screen.
        yield call(
          gameModule.sagas.preround.nextRound,
          gameModule.actions.preround.nextRound.request({
            game: { id: gameId, type: "games" },
          }),
        );

        yield put(transitions.waitplayers.submitting.success());
      } catch (error) {
        yield put(transitions.waitplayers.submitting.error(error));
      }
    },
  },
};

// An intermediate state before the first round and between rounds.
// If everything goes well, this state is never actually reached.
// But, if the call to "sagas.preround.nextRound" fails and the app crashes, the game is in the preround
// state in the backend. This state will then be entered and the situation resolved.
const preround = {
  resolve: {
    *init(action) {
      const gameId = action.payload.id;

      try {
        yield call(
          gameModule.sagas.preround.nextRound,
          gameModule.actions.preround.nextRound.request({
            game: { id: gameId, type: "games" },
          }),
        );
        yield put(transitions.preround.submitting.success());
      } catch (error) {
        yield put(transitions.preround.submitting.error(error));
      }
    },
  },
};

const round = {
  resolve: {
    *step() {
      try {
        const state = yield select();
        yield call(rounds.sagas.round.current, { game: state.game });

        const newState = yield select();
        const currentRound = newState.game.current.round;

        // State guards
        const guards = {
          round: {
            pickChoice: () => currentRound.roundState === "pick-choice",
            pickCandidates: () => currentRound.roundState === "pick-candidates", // Current default
          },
        };

        switch (true) {
          case guards.round.pickChoice():
            // Transition to round.pickChoice state
            yield put(transitions.round.resolve.pickChoice());
            break;

          case guards.round.pickCandidates():
          default:
            // Transition to round.pickCandidates state
            yield put(transitions.round.resolve.pickCandidates());
            break;
        }
      } catch (error) {
        yield put(transitions.round.resolve.error(error));
      }
    },
  },

  pickCandidates: {
    resolve: {
      *init() {
        const state = yield select();
        const currentRound = state.game.current.round;

        if (currentRound.roundState === "pick-candidates") {
          yield put(rounds.actions.candidates.refresh.request({ round: currentRound }));
          yield put(transitions.round.pickCandidates.picking.start());
        } else if (currentRound.roundState === "skipped") {
          // An intermediate state that needs to be resolved
          yield put(transitions.round.pickChoice.prepareNextRound.entry());
        } else {
          throw new Error(
            `Invalid round state in pickCandidates#resolve: ${currentRound.roundState}`,
          );
        }
      },
    },

    picking: {
      *pick(action) {
        yield call(rounds.sagas.candidates.pick, action);
      },
      *remove(action) {
        yield call(rounds.sagas.candidates.remove, action);
      },
    },

    submitting: {
      *request(action) {
        try {
          yield call(rounds.sagas.candidates.submit, action);
          yield put(rounds.actions.candidates.refresh.stop());
          yield put(transitions.round.pickCandidates.submitting.success());
        } catch (error) {
          yield put(transitions.round.pickCandidates.submitting.error(error));
        }
      },
    },

    skip: {
      *request(action) {
        try {
          yield call(rounds.sagas.candidates.skip, action);
          yield put(rounds.actions.candidates.refresh.stop());
          yield put(transitions.round.pickCandidates.skipping.success());
        } catch (error) {
          yield put(transitions.round.pickCandidates.skipping.error(error));
          throw error;
        }
      },
    },
  },

  pickChoice: {
    resolve: {
      *init() {
        const state = yield select();
        const currentRound = state.game.current.round;

        if (currentRound.roundState === "pick-choice") {
          yield call(rounds.sagas.candidates.get, { payload: { round: currentRound } });
          yield call(playerVotes.sagas.get, { kind: "round" });
          yield put(playerVotes.actions.refresh.request({ kind: "round" }));
          yield put(transitions.round.pickChoice.picking.start());
        } else if (currentRound.roundState === "skipped") {
          // Move on
          yield put(transitions.round.pickChoice.prepareNextRound.entry());
        } else {
          throw new Error(`Invalid round state in pickChoice#resolve: ${currentRound.state}`);
        }
      },
    },

    picking: {
      *pick(action) {
        yield put(rounds.actions.choice.pick(action.payload));
      },
    },

    submitting: {
      *request(action) {
        try {
          yield call(
            rounds.sagas.choice.patch,
            rounds.actions.choice.patch.request(action.payload),
          );
          yield put(playerVotes.actions.refresh.stop());
          yield put(transitions.round.pickChoice.submitting.success());
        } catch (error) {
          yield put(transitions.round.pickChoice.submitting.error(error));
        }
      },
    },

    prepareNextRound: {
      *entry() {
        yield call(rounds.sagas.round.nextRound);
        yield put(transitions.round.pickChoice.prepareNextRound.success());
      },
    },
  },
};

const pickTopaasia = {
  resolve: {
    *init() {
      const gameId = yield select((s) => s.game.id);
      const playedRounds = yield select((s) => Object.values(s.rounds.byId));
      const topaasiaChoiceRounds = playedRounds.filter(
        (r) => r.chosenCandidateId !== null && r.roundState === "complete",
      );

      if (topaasiaChoiceRounds.length < 2) {
        const topaasiaChoiceRound = first(topaasiaChoiceRounds);
        // Nothing to choose, continue immediately
        yield put(
          transitions.pickTopaasia.picking.submit({
            game: { id: gameId },
            topaasia: { id: topaasiaChoiceRound.id, type: "rounds" },
          }),
        );
      } else {
        // Proceed to picking
        yield call(playerVotes.sagas.get, { kind: "topaasia" });
        yield put(playerVotes.actions.refresh.request({ kind: "topaasia" }));
      }
    },
  },
  picking: {
    *pick(action) {
      yield put(gameModule.actions.topaasia.pick(action.payload));
    },
  },

  submitting: {
    *request(action) {
      try {
        yield call(
          gameModule.sagas.topaasia.patch,
          gameModule.actions.topaasia.patch.request(action.payload),
        );
        yield put(playerVotes.actions.refresh.stop());
        yield put(transitions.pickTopaasia.submitting.success());
      } catch (error) {
        yield put(transitions.pickTopaasia.submitting.error(error));
      }
    },
  },
};

const crystallization = {
  submitting: {
    *request(action) {
      const { payload } = action;

      try {
        const state = yield select();
        const { game } = state;
        yield call(
          gameModule.sagas.crystallization.patch,
          gameModule.actions.crystallization.patch.request({
            game: { id: game.id, type: "games" },
            visibility: get(payload, "visibility", "self"),
          }),
        );

        yield put(transitions.crystallization.submitting.success());
      } catch (error) {
        yield put(transitions.crystallization.submitting.error(error));
      }
    },
  },
};

const complete = {
  *resolve() {
    yield put(gameModule.actions.tick.stop());
  },
};

export const states = {
  loading,
  resolve,
  setup,
  waitplayers,
  preround,
  round,
  pickTopaasia,
  crystallization,
  complete,
};
