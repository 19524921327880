import { all, takeEvery, takeLatest } from "redux-saga/effects";

import * as linkRequests from "./modules/link-requests";
import * as login from "./modules/login";
import * as states from "./modules/states";
import * as players from "./modules/players";
import * as rounds from "./modules/rounds";
import * as playerVotes from "./modules/player-votes";
import * as game from "./modules/game";
import * as intro from "./modules/intro";

import { dashboardSagas } from "./modules/dashboard";
import { takeLatestDeep } from "./modules/shared/utils";
import { gameNoteSagas } from "./modules/notes/sagas";
import { gameNoteActions } from "./modules/notes/actions";

export function* sagas() {
  yield all([
    // Service messages
    takeLatest(intro.actions.serviceMessages.get.request, intro.sagas.serviceMessages.get),

    // Authentication
    takeLatest(linkRequests.actions.post.request, linkRequests.sagas.post),

    takeLatest(login.actions.loginInfo.get.request, login.sagas.loginInfo.get),
    takeLatest(login.actions.session.selfOrganizations.request, login.sagas.session.selfOrganizations),
    takeLatest(login.actions.session.context.request, login.sagas.session.sessionContext),
    takeLatest(login.actions.session.login.request, login.sagas.session.login),
    takeLatest(login.actions.session.link.request, login.sagas.session.linkRequest),
    takeLatest(login.actions.session.logout.request, login.sagas.session.logout),
    takeLatest(login.actions.session.status.expired, login.sagas.session.expired),
    takeLatest(login.actions.session.organization.pick.request, login.sagas.session.organization.pick),
    takeLatest(login.actions.session.token.exchange.request, login.sagas.session.tokenExchange),

    // States:
    // - loading
    // - resolve
    // - setup
    // - waitplayers
    // - preround
    // - round
    // - pickTopaasia
    // - crystallization
    // - complete

    // State: loading
    takeLatest(states.transitions.loading.request, states.sagas.states.loading.request),

    // State: resolve
    takeLatest(states.transitions.resolve.current, states.sagas.states.resolve.current),

    // State: setup

    takeLatest(
      states.transitions.setup.submitting.request,
      states.sagas.states.setup.submitting.request,
    ),

    // State: waitplayers
    takeLatest(
      states.transitions.waitplayers.resolve.request,
      states.sagas.states.waitplayers.resolve.request,
    ),
    takeLatest(
      states.transitions.waitplayers.submitting.request,
      states.sagas.states.waitplayers.submitting.request,
    ),
    takeLatest(
      players.actions.remove.request,
      players.sagas.remove,
    ),
    takeLatest(
      players.actions.fetch.request,
      players.sagas.fetch,
    ),

    // State: preround
    takeLatest(
      states.transitions.resolve.preround,
      states.sagas.states.preround.resolve.init,
    ),

    // State: round
    // - resolve
    // - pickCandidates
    // - pickChoice

    takeLatest(
      states.transitions.round.resolve.step,
      states.sagas.states.round.resolve.step,
    ),

    takeLatest(
      states.transitions.round.pickCandidates.resolve,
      states.sagas.states.round.pickCandidates.resolve.init,
    ),

    takeLatest(
      states.transitions.round.pickCandidates.picking.pick,
      states.sagas.states.round.pickCandidates.picking.pick,
    ),

    takeLatest(
      states.transitions.round.pickCandidates.picking.remove,
      states.sagas.states.round.pickCandidates.picking.remove,
    ),

    takeLatest(
      states.transitions.round.pickCandidates.submitting.request,
      states.sagas.states.round.pickCandidates.submitting.request,
    ),

    takeLatest(
      states.transitions.round.pickCandidates.skipping.request,
      states.sagas.states.round.pickCandidates.skip.request,
    ),

    takeLatest(
      states.transitions.round.pickChoice.resolve,
      states.sagas.states.round.pickChoice.resolve.init,
    ),

    takeLatest(
      states.transitions.round.pickChoice.picking.pick,
      states.sagas.states.round.pickChoice.picking.pick,
    ),

    takeLatest(
      states.transitions.round.pickChoice.submitting.request,
      states.sagas.states.round.pickChoice.submitting.request,
    ),

    takeLatest(
      states.transitions.round.pickChoice.prepareNextRound.entry,
      states.sagas.states.round.pickChoice.prepareNextRound.entry,
    ),

    takeLatest(
      playerVotes.actions.command.startVote, playerVotes.sagas.create,
    ),

    takeLatest(
      playerVotes.actions.command.endVote, playerVotes.sagas.terminate,
    ),

    // State: pickTopaasia
    takeLatest(
      states.transitions.pickTopaasia.resolve,
      states.sagas.states.pickTopaasia.resolve.init,
    ),

    takeLatest(
      states.transitions.pickTopaasia.picking.pick,
      states.sagas.states.pickTopaasia.picking.pick,
    ),

    takeLatest(
      states.transitions.pickTopaasia.submitting.request,
      states.sagas.states.pickTopaasia.submitting.request,
    ),

    // State: crystallization
    takeLatestDeep(
      game.actions.findings.post.request,
      (action) => action.payload.topicId,
      game.sagas.findings.post,
    ),

    takeEvery(
      game.actions.actionItems.post.request,
      game.sagas.actionItems.post,
    ),

    takeLatestDeep(
      game.actions.actionItems.patch.request,
      (action) => action.payload.id,
      game.sagas.actionItems.patch,
    ),

    takeLatest(
      game.actions.actionItems.destroy.request,
      game.sagas.actionItems.destroy,
    ),

    takeLatest(
      game.actions.feedback.post.request,
      game.sagas.feedback.post,
    ),

    takeLatest(
      states.transitions.crystallization.submitting.request,
      states.sagas.states.crystallization.submitting.request,
    ),

    takeLatest(game.actions.playerParticipations.list.request, game.sagas.playerParticipations.list),
    takeLatest(game.actions.playerParticipations.get.request, game.sagas.playerParticipations.get),
    takeLatest(game.actions.playerParticipations.post.request, game.sagas.playerParticipations.post),
    takeLatest(game.actions.playerParticipations.patch.request, game.sagas.playerParticipations.patch),
    takeLatest(game.actions.playerIdeas.destroy.request, game.sagas.playerIdeas.destroy),

    // State: complete
    takeLatest(
      states.transitions.complete.resolve,
      states.sagas.states.complete.resolve,
    ),

    // Notes for all game states
    takeLatest(gameNoteActions.get.request, gameNoteSagas.get),
    takeLatest(gameNoteActions.patch.request, gameNoteSagas.patch),

    // Closing and opening the game code
    takeLatest(game.actions.setGameCodeClosed.request, game.sagas.setGameCodeClosed),

    // Enabling and disabled player comments
    takeLatest(game.actions.enablePlayerGameNotes.request, game.sagas.enablePlayerGameNotes),

    // Polling tasks
    players.sagas.pollDriver(),
    rounds.sagas.pollDriver(),
    playerVotes.sagas.pollDriver(),
    game.sagas.playerParticipations.pollDriver(),
    gameNoteSagas.pollDriver(),
    game.sagas.tickDriver(),

    // All dashboard action-saga links
    ...dashboardSagas.sagas,
  ]);
}
