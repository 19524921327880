import { call, put } from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import { get as safeGet } from "lodash";
import { api, actions } from ".";
import { logoutService, organizationService, sessionService, tokenService } from "../../services";

export const loginInfo = {
  * get() {
    try {
      const response = yield call(api.get);
      const payload = response ? response.data : {};

      yield put(actions.loginInfo.get.success(payload));
      
      if (payload.selfOrganizations.length === 1) {
        const currentId = payload.selfOrganizations[0].id;
        organizationService.setCurrent(currentId); 
      }
    } catch (error) {
      yield put(actions.loginInfo.get.error(error));

      if (error.response && error.response.status === 403) {
        yield put(actions.session.status.expired());
      } else {
        throw error;
      }
    }
  },
};

export const session = {
  * selfOrganizations() {
    try {
      const response = yield call(api.selfOrganizations);
      const payload = response ? response.data : {};

      yield put(actions.session.selfOrganizations.success(payload));
    } catch (error) {
      yield put(actions.session.selfOrganizations.error(error));
    }
  },
  * sessionContext() {
    try {
      const currentOrganizationId = organizationService.getCurrent()
      const response = yield call(api.session, { organizationId: currentOrganizationId });
      const payload = response ? response.data : {};
      yield put(actions.session.context.success(payload));
    } catch (error) {
      yield put(actions.session.context.error(error));
    }
  },

  * login(action) {
    try {
      const { email, password } = action.payload;
      yield call(sessionService.remove);
      yield call(tokenService.remove);
      yield call(organizationService.removeCurrent);

      const response = yield call(api.login, { email, password });

      yield call(tokenService.set, response.data.token);
      yield put(actions.session.login.success());
      yield put(push("/play"));
    } catch (error) {
      const { status } = safeGet(error, "response", { status: 0 });

      if (status === 403) { // Forbidden
        yield put(actions.session.login.error({ code: "invalid_credentials" }));
      } else if (status === 429) { // Too many requests
        yield put(actions.session.login.error({ code: "rate_limited" }));
      }
    }
  },

  * linkRequest(action) {
    try {
      const { email } = action.payload;

      yield call(sessionService.remove);
      yield call(tokenService.remove);
      yield call(organizationService.removeCurrent);

      yield call(api.linkRequest, { email });
      yield put(actions.session.link.success());
    } catch (error) {
      yield put(actions.session.link.error(error));
    }
  },

  * tokenExchange(action) {
    try {
      const { userId, token } = action.payload;
      const response = yield call(api.tokenExchange, { userId, token });
      yield call(tokenService.set, response.data.token);
      yield put(actions.session.token.exchange.success());
      yield put(replace("/play"));
    } catch (error) {
      yield put(actions.session.token.exchange.error(error));
    }
  },

  * logout() {
    yield call(logoutService.logout);
    yield put(actions.session.logout.success());

    // Do a hard replace/refresh to ensure destruction of redux state
    // and its history.
    window.location.replace("/");
  },

  organization: {
    * pick(action) {
      const { organizationId, route } = action.payload;
      yield call(organizationService.setCurrent, organizationId);

      if (route) {
        yield put(push(route));
      }
    },
  },

  * expired() {
    yield call(sessionService.remove);
    yield call(tokenService.remove);
    yield call(organizationService.removeCurrent);

    // Do a hard replace/refresh to ensure destruction of redux state
    // and its history.
    window.location.replace("/");
  },
};
