import { organization as actions } from "../actions";
import { flattenOrganizationUmbrella } from "./utils";

export const initialState = {
  collections: {
    organization: null,
    organizationUmbrella: null,
    organizationUmbrellaOrgs: null,
  },
  status: {
    getOrganizationUmbrella: "submit", // submit | submitting | success | error  
    destroyOrganization: {
      state: "initial", // initial, preparing, prepared, destroying, destroyed, error
      payload: null,
    },
  },
};

// NOTE: These reducers do not use "createActions" since these are combined with other dashboard
// reducers in ../reducers.js

export const collections = {
  [actions.get.success]: (state, action) => ({
    ...state,
    organization: action.payload,
  }),
  [actions.getOrganizationUmbrella.success]: (state, action) => ({
    ...state,
    organizationUmbrella: {
      [action.payload.view]: action.payload.payload,
    },
    organizationUmbrellaOrgs: {
      [action.payload.view]: flattenOrganizationUmbrella(action.payload.payload),
    },
  }),
};

export const status = {
  [actions.getOrganizationUmbrella.request]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      getOrganizationUmbrella: "submitting",
    },
  }),
  [actions.getOrganizationUmbrella.success]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      getOrganizationUmbrella: "success",
    },
  }),
  [actions.getOrganizationUmbrella.error]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      getOrganizationUmbrella: "error",
    },
  }),
  [actions.destroy.prepare.request]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      destroyOrganization: { state: "preparing", payload: null },
    },
  }),

  [actions.destroy.prepare.success]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      destroyOrganization: { state: "prepared", payload: null },
    },
  }),

  [actions.destroy.prepare.error]: (state, action) => ({
    ...state,
    organization: {
      ...state.organization,
      destroyOrganization: {
        state: "error",
        payload: {
          code: "prepare_error",
          error: action.payload,
        },
      },
    },
  }),

  [actions.destroy.success]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      destroyOrganization: { state: "destroying", payload: null },
    },
  }),

  [actions.destroy.success]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      destroyOrganization: { state: "destroyed", payload: null },
    },
  }),

  [actions.destroy.error]: (state, action) => ({
    ...state,
    organization: {
      ...state.organization,
      destroyOrganization: {
        state: "error",
        payload: {
          error: "destroy_error",
          code: action.payload.status === 403 ? "invalid_code" : "unknown_error",
        },
      },
    },
  }),

  [actions.destroy.cancel]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      destroyOrganization: { state: "initial" },
    },
  }),
};
