import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { actions } from ".";
import * as dashboardActions from "../dashboard/actions";

const initialStates = {
  collections: {
    loginInfo: {
      selfUser: null,
      selfOrganizations: null,
    },
    currentOrganization: null,
  },
  status: {
    loginInfo: {
      isLoading: false,
    },
    session: {
      loggingIn: false,
      selfOrganizations: "submit", // submit | submitting | success | error
      linkRequest: "submit", // submit | submitting | success | error
      loginRequest: "submit", // submit | submitting | success | invalid_credentials | trial_expired
      tokenExchange: "submit", // submit | submitting | success | error
    },
  },
};

const collections = handleActions(
  {
    [actions.loginInfo.get.success]: (state, action) => ({
      ...state,
      loginInfo: action.payload,
    }),
    [actions.session.context.success]: (state, action) => ({
      ...state,
      currentOrganization: action.payload.sessionContext.currentOrganization,
      loginInfo: {
        ...state.loginInfo,
        selfUser: action.payload.sessionContext.selfUser,
      },
    }),
    [actions.session.selfOrganizations.success]: (state, action) => ({
      ...state,
      loginInfo: {
        ...state.loginInfo,
        selfOrganizations: action.payload.selfOrganizations,
      },
    }),
    [dashboardActions.users.userAttributes.patch.success]: (state, action) => ({
      ...state,
      loginInfo: {
        ...state.loginInfo,
        selfUser: state.loginInfo.selfUser ? {
          ...state.loginInfo.selfUser,
          userAttributes: action.payload,
        } : null,
      },
    }),
  },
  initialStates.collections,
);

const status = handleActions(
  {
    // Login info
    [actions.loginInfo.get.request]: (state) => ({ ...state, loginInfo: { isLoading: true } }),
    [actions.loginInfo.get.success]: (state) => ({ ...state, loginInfo: { isLoading: false } }),
    [actions.loginInfo.get.error]: (state) => ({ ...state, loginInfo: { isLoading: false } }),

    [actions.session.selfOrganizations.request]: (state) => ({
      ...state,
      session: { 
        ...state.session, 
        selfOrganizations: "submitting",
      },
    }),
    [actions.session.selfOrganizations.success]: (state) => ({
      ...state,
      session: { 
        ...state.session, 
        selfOrganizations: "success",
      },
    }),
    [actions.session.selfOrganizations.error]: (state) => ({
      ...state,
      session: { 
        ...state.session, 
        selfOrganizations: "error",
      },
    }),

    // Login
    [actions.session.login.request]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: true, loginRequest: "submitting" },
    }),
    [actions.session.login.success]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: false, loginRequest: "success" },
    }),
    [actions.session.login.error]: (state, action) => ({
      ...state,
      session: {
        ...state.session,
        loggingIn: false,
        loginRequest: action.payload.code,
      },
    }),
    [actions.session.link.request]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: true, linkRequest: "submitting" },
    }),
    [actions.session.link.success]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: false, linkRequest: "success" },
    }),
    [actions.session.link.error]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: false, linkRequest: "error" },
    }),
    [actions.session.link.reset.form]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: false, linkRequest: "submit" },
    }),
    [actions.session.token.exchange.request]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: true, tokenExchange: "submitting" },
    }),
    [actions.session.token.exchange.success]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: false, tokenExchange: "success" },
    }),
    [actions.session.token.exchange.error]: (state) => ({
      ...state,
      session: { ...state.session, loggingIn: false, tokenExchange: "error" },
    }),
  },
  initialStates.status,
);

export const reducer = combineReducers({
  collections,
  status,
});
