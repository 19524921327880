export function groupOrganizationsByHierarchy(items) {
  const nodeMap = new Map();

  // Create a node for each item and add to the map
  items.forEach((item) => {
    if (!nodeMap.has(item.id)) {
      nodeMap.set(item.id, { ...item, children: [], isRootNode: false });
    }
  });

  // Set children for each node
  items.forEach((item) => {
    const currentNode = nodeMap.get(item.id);
    if (!item.parentOrganizationId || !nodeMap.get(item.parentOrganizationId)) {
      currentNode.isRootNode = true;
    }
    if (item.parentOrganizationId) {
      const parentNode = nodeMap.get(item.parentOrganizationId);
      if (parentNode) {
        parentNode.children.push(currentNode);
      }
    }
  });

  // Extract the root nodes (those without a parent)
  return Array.from(nodeMap.values()).filter((node) => node.isRootNode);
}

export const flattenOrganizationUmbrella = (orgUmbrella) => {
  function flattenOrganization(org) {
    const result = [{ ...org }];
  
    if (org.childOrganizations && org.childOrganizations.length > 0) {
      org.childOrganizations.forEach((childOrg) => {
        result.push(...flattenOrganization(childOrg));
      });
    }
  
    return result.map(({ childOrganizations, ...rest }) => rest);
  }
  
  return flattenOrganization(orgUmbrella)
}

export const filterOrganizationUmbrella = (orgUmbrella, filterTerm) => {
  const filterNode = (node) => {
    const filteredNode = JSON.parse(JSON.stringify(node));
    filteredNode.organizationUsers = node.organizationUsers.filter((orgUser) => 
      orgUser.user.email.toLowerCase().includes(filterTerm.toLowerCase()),
    );

    if (node.childOrganizations && node.childOrganizations.length > 0) {
      filteredNode.childOrganizations = node.childOrganizations.map(filterNode);
    }

    return filteredNode;
  };

  const orgUmbrellaCopy = JSON.parse(JSON.stringify(orgUmbrella));

  return filterNode(orgUmbrellaCopy);
};

export const findFirstOrganizationWithAnyUsers = (organizationUmbrella) => {
  const renderNode = (orgs) => 
    orgs.reduce((found, org) => {
      if (found) return found;
      if (org.organizationUsers && org.organizationUsers.length > 0) return org;
      if (org.childOrganizations && org.childOrganizations.length > 0) {
        return renderNode(org.childOrganizations);
      }
      return null;
    }, null);

  return renderNode([organizationUmbrella])
};

export const findOrganizationById = (organizationUmbrella, targetId) => {
  const searchNode = (orgs) => 
    orgs.reduce((found, org) => {
      if (found) {
        return found;
      }
      
      if (org.id === targetId) {
        return org;
      }
      
      if (org.childOrganizations && org.childOrganizations.length > 0) {
        return searchNode(org.childOrganizations, targetId);
      }
      
      return null;
    }, null);

  return searchNode([organizationUmbrella]);
}